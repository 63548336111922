import React, { useEffect, useState } from "react";
import Loading from "../../components/common/Loading";
import KnowledgeBaseForm from "../../components/modules/knowledge/knowledgeBaseForm";
import { useHistory } from "react-router";
import ApiLoader from "../../components/common/ApiLoader";
import { AppHeaderComponent, KnowledgeBaseApi } from "@unity/components";
export default function KnowledgeBaseCreate({ route, context }) {
  const [data, setData] = useState({ fileRecords: [] });
  const [loading, setLoading] = useState({ status: false, data: false });
  const [errors, setErrors] = useState(false);

  let history = useHistory();

  const validate = {
    subject: "name",
  };

  const validation = async (obj) => {
    const x = {};
    Object.keys(obj).map((key) => {
      if (validate[key]) {
        if (!obj[key]) {
          x[key] = true;
        }
      }
    });

    if (Object.keys(x).length > 0) {
      return x;
    } else {
      return true;
    }
  };

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const handleFileChange = (files) => {
    setData({ ...data, documents: files });
  };

  const handleSubmit = async () => {
    setLoading({ status: true, data: "Saving Records.. Please Wait !" });

    const x = await validation(data);
    if (x == true) {
      const result = await KnowledgeBaseApi.createKnowledgeBaseRecord(data);
      setLoading({
        status: true,
        data: "Knowledge Base Created! Please Wait....",
      });
      setTimeout(() => {
        history.push("/knowledge/index");
      }, 5000);
    } else {
      setErrors(x);
      setLoading({ status: false });
    }
  };

  if (data) {
    return (
      <div>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="knowledge"
          subpage="create"
          saveBtn={handleSubmit}
        />
        <ApiLoader status={loading.status} data={loading.data} />

        <KnowledgeBaseForm
          data={data}
          handleChange={handleChange}
          type="create"
        />
      </div>
    );
  } else {
    return <Loading />;
  }
}
